































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ProductPageStore } from '@elitepage/store/product-page'
import { ElitepageStore } from '@elitepage/store/elitepage'

@Component
export default class ProductPage extends Vue {
    @Prop({ type: String }) producerSlug!: IProducer['slug']

    @ProductPageStore.State('productPage') productPage: IElitepage
    @ProductPageStore.State('product') product: IElitepage
    @ElitepageStore.State('producer') producer: IProducer

    @ProductPageStore.Action('loadProductPage') loadProductPage: (producerSlug: IProducer['slug']) => void
    @ProductPageStore.Action('loadProducts') loadProducts: (producerId: IProducer['id']) => void

    async mounted() {
        await this.loadProductPage(this.producerSlug)
        await this.loadProducts(this.producer.id)
    }
}
